<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        a) What is the mass in amu of
        <number-value :value="taskState.getValueBySymbol('nAtoms').content" />
        atoms of
        <chemical-isotope-value :value="chemicalIsotope" />? Note that the atomic mass of
        <chemical-isotope-value :value="chemicalIsotope" />
        is
        <latex-number :number="isotopeMass" />
        amu.
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        :prepend-text="'$\\text{Mass:}$'"
        :append-text="'$\\text{amu}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-1">b) Convert the mass in amu to grams.</p>

      <calculation-input
        v-model="inputs.input2"
        class="mb-1"
        :prepend-text="'$\\text{Mass:}$'"
        :append-text="'$\\text{g}$'"
        :disabled="!allowEditing"
        show-helpful-hint
        helpful-hint-position="below"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import ChemicalIsotopeValue from '../ChemicalIsotopeValue';
import CalculationInput from '../inputs/CalculationInput';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question3',
  components: {
    LatexNumber,
    CalculationInput,
    ChemicalIsotopeValue,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    chemicalIsotope() {
      return this.taskState.getValueBySymbol('isotope').content;
    },
    isotopeMass() {
      return this.chemicalIsotope.mass;
    },
  },
};
</script>
